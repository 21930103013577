.App {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
}

.App-logo {
  height: 67px;	
  width: 153px;
  /* pointer-events: none; */
}
