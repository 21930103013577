.survey {
  box-shadow: 0 4px 8px 0 rgba(55, 93, 112, 0.1);
  background-color: white;
  color: #333;
  width: 90%;
  max-width: 600px;
  padding-top: 50px;
  padding-bottom: 5px;
  position: relative;
  margin-top: 60px;
}

.survey.error-display {
  padding-top: 25px;
  padding-bottom: 25px;
}

.survey,
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  margin-top: 20px;
  width: 80%;
}

.title {
  width: 100%;
  margin: 20px 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
}

.feedback-title {
  font-size: 20px;
  font-weight: 501;
  color: #2b5597;
}

.submit-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.form-intro {
  width: 100%;
  text-align: center;
}

.body {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.csat-emoji-img {
  position: absolute;
  top: -35px;
  height: 100px;
  background: #fff;
  padding: 15px;
  border-radius: 50%;
}

.form-field {
  width: 100%;
  margin: 10px 0;
}

.form-field input {
  display: block;
  width: 100%;
  border: none;
  outline: none;
  margin: 30px 0 10px 0;
  padding: 5px;
  font-size: 18px;
}

.form-field form.ui.form textarea {
  display: block;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 5px;
  font-size: 14px;
  border-bottom: 1px solid #999;
  /* transition: border-width 0.6s linear; */
  /* transition: border-color 0.6s linear; */
}

.form-field form.ui.form textarea:focus {
  border-bottom: 2px solid #00a9e0;
}

.form-field label {
  font-size: 12px;
  color: #5f6972;
}

.submit-btn {
  padding: 10px 20px !important;
  font-size: 16px !important;
  font-weight: bold;
}

.submit-btn:hover {
  background-color: #0083ad;
}

.submit-btn:disabled {
  background: #dadada !important;
  color: #a1a3a4 !important;
}

.success p {
  margin: 3px 0;
  padding: 0;
  font-size: 15px;
}

.success-icon {
  margin-top: 35px;
  margin-bottom: 20px;
}

.changeRating {
  color: #96969b;
  font-family: Roboto;
  font-size: 12px;
  line-height: 22px;
  display: inline;
}

.errorMessage,
.errorMessage > p {
  text-align: center;
  line-height: 22px;
}
.errorMessage span {
  color: #2b5597;
  font-weight: bold;
}

/* semantic CSS overwrite */
.submit-btn {
  background-color: #2b5597 !important;
}

.outside-emoticons {
  margin-top: 15px;
}

.outside-emoticons .changeRating {
  color: #555;
}
