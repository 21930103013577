.emoticons {
  display: flex;
  justify-content: center;
  padding: 0px 0 10px 0;
}

.emoticons img {
  padding: 0 10px;
  cursor: pointer;
  height: 40px;
  width: 40px;
}
